import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ['active']
  static targets = ["active"]
  static values = { defaultTab: String }

  connect() {
    this.activeTargets.filter(elt => elt.dataset.value === this.defaultTabValue).forEach(elt => elt.classList.add(this.activeClass));
  }

  select(event) {
    let tab = event.target.dataset.value
    this.defaultTabValue = tab
    this.updateUrlHistory("selected", tab)
  }

  defaultTabValueChanged(now, before) {
    this.activeTargets.filter(elt => elt.dataset.value === before).forEach(elt => elt.classList.remove(this.activeClass));
    this.activeTargets.filter(elt => elt.dataset.value === now).forEach(elt => elt.classList.add(this.activeClass));
  }

  updateUrlHistory(key, value){
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(key, value);

    window.history.pushState({}, "", currentUrl.toString());
  }
}
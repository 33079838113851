import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  EXTENTIONS = ["image/jpeg","image/png","image/svg+xml"]
  MAX_SIZE = 524288000
  ERRORS = ""
  DRAGGABLE = "draggable"
  DROPPABLE = "droppable"
  DATATRANSFER = new DataTransfer()

  static targets = ["input", "label", "error", "images"]
  static values = { state: String }

  dragenter(event){
    event.preventDefault()
    this.stateValue = this.DROPPABLE
  }

  dragover(event){
    event.preventDefault()
    return false
  }

  drop(event){
    event.preventDefault();
    if (event.dataTransfer.items) {
      this.handleFiles(event.dataTransfer.items);
    } else {
      this.handleFiles(event.dataTransfer.files);
    }
  }

  dragleave(event){
    event.preventDefault()
    this.stateValue = this.DRAGGABLE
  }

  change(event){
    event.preventDefault();
    this.handleFiles(event.target.files);
  }

  stateValueChanged(){
    this.labelTarget.innerHTML = this.stateValue == "droppable" ? "Deposer votre image" : "Glisser déposer ou <span class='input_file__undeline'>Cliquer pour choisir une image</span>"
  }

  handleFiles(files){
    this.reset();
    this.stateValue = this.DRAGGABLE;
    [...files].forEach((file, i) => {
      this.handleFile(file.kind === "file" ? file.getAsFile() : file);
    })
    this.inputTarget.files = this.DATATRANSFER.files
    this.displayErrors()
  }

  handleFile(file){
    if(this.valid(file)){
      this.displayFile(file)
      this.setFile(file)
    }
  }

  reset(){
    this.DATATRANSFER = new DataTransfer()
    this.ERRORS = ""
    this.imagesTarget.innerHTML = ""
  }

  displayErrors(){
    this.errorTarget.innerHTML = this.ERRORS
  }

  displayFile(file){
    var reader = new FileReader()
    reader.onloadend = function (readerEvt) {
      let node = document.createElement("div")
      let span = document.createElement("span")
      span.classList.add("icon-Cross")
      span.dataset.action = "click->forms--inputs--file#remove"
      span.dataset.file = file.name
      let img = document.createElement("img")
      img.src = readerEvt.target.result
      node.appendChild(img)
      node.appendChild(span)

      this.imagesTarget.insertAdjacentElement("beforeend", node)

    }.bind(this)

    reader.readAsDataURL(file)
  }

  remove(event){
    const updatedFiles = Array.from(this.inputTarget.files).filter(
      (f) => f.name !== event.target.dataset.file
    );
    const dataTransfer = new DataTransfer()
    updatedFiles.forEach((file) => dataTransfer.items.add(file))
    this.inputTarget.files = dataTransfer.files
    event.target.parentElement.remove()
  }

  valid(file){
    var validate = true;
    // if(file.type.startsWith("image/"))
    if(!this.EXTENTIONS.includes(file.type)){
      this.ERRORS += `Format invalide pour l'image ${file.name}, format donné: ${file.type}\n`
      validate = false
    }
    // > à 500 Mo
    if(file.size > this.MAX_SIZE){
      this.ERRORS += `Taille invalide pour l'image ${file.size}, taille donnée: ${(file.size / 1024).toFixed(2)} Mo`
      validate = false
    }

    return validate
  }

  setFile(file){
    this.DATATRANSFER.items.add(file)
  }
}

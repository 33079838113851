import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "table", "add", "totalProduct", "totalQuantity", "total" ]

  connect = () => {
    let element = document.getElementById("transfer_establishment_id")
    this.establishmentId = element.value
    element.addEventListener("change", event => {
      this.establishmentId = event.target.value
      this.onEstablishmentChange(event)
    })
  }

  onEstablishmentChange = async event => {
    const uniq_id = `${Date.now()}`
    if(this.establishmentId){
      this.updateView()
      this.innerHtmlOnEstablishmentChange(uniq_id)
    } else {
      this.cleanHtmlOnEstablishmentChange()
    }
  }

  onRemoveLine = (event) => {
    event.preventDefault()
    if(this.tableTarget.querySelectorAll("tbody > tr").length > 1){
      event.currentTarget.closest("tr").remove()
      this.countQuantity()
    }
  }

  onAddLine = (event) => {
    event.preventDefault()
    const uniq_id = `${Date.now()}`
    this.tableTarget.querySelector("tbody").insertAdjacentHTML("beforeend", this.htmlLine(uniq_id))
  }

  updateView() {
    this.addTarget.classList.remove("hidden")
    this.totalTarget.classList.remove("hidden")
    this.countProduct()
    this.countQuantity()
  }

  innerHtmlOnEstablishmentChange = (uniq_id) => {
    this.tableTarget.innerHTML = this.HtmlOnEstablishmentChange(uniq_id)
  }

  cleanHtmlOnEstablishmentChange = () => this.tableTarget.innerHTML = ""

  // 15_102 => correspond au variant 15 avec l unité 102
  getUnitVariantInformation = async (event) => {
    let uniq_id = event.currentTarget.id
    let variant_id_unit_id = event.currentTarget.value
    const url = this.buildURL(`/admins/variants/unit_variant_information.turbo_stream?target=${uniq_id}&variant_id=${variant_id_unit_id.split("_").at(0)}&unit_id=${variant_id_unit_id.split("_").at(-1)}`)
    try {
      this.element.dispatchEvent(new CustomEvent("loadstart"))
      const html = await this.doFetch(url)
      Turbo.renderStreamMessage(html)
      this.element.dispatchEvent(new CustomEvent("load"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
    } catch (error) {
      this.element.dispatchEvent(new CustomEvent("error"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
      throw error
    } finally {
      this.countProduct()
    }
  }

  countProduct = () => this.totalProductTarget.innerHTML = Array.from(this.tableTarget.querySelectorAll("input[name='variant_id_unit_id']")).filter( input => input.value.trim() != "").length 

  countQuantity = () => { this.totalQuantityTarget.innerHTML = Array.from(this.tableTarget.querySelectorAll("input[name='transfer[transfer_items_attributes][][quantity]'")).reduce((total, input) => {
    const value = parseFloat(input.value)
    if(!isNaN(value)){
      return total + value
    }
    return total
  }, 0) }

  buildURL = path => {
    const url = new URL(path, window.location.href)

    return url.toString()
  }

  doFetch = async (url) => {
    const response = await fetch(url, this.fetchOptions())

    if(!response.ok){
      throw new Error(`Server responded with status ${response.status}`)
    }

    const html = await response.text()

    return html
  }

  fetchOptions = () => { return { headers: { "X-Requested-With": "XMLHttpRequest" }}}

  htmlLine = (uniq_id) => `
    <tr>
      <td>
        <div class="field"><div class="flex flex-col gap-1 h-full">
          <div class="autocomplete" data-controller="forms--inputs--auto-complete" data-url="/admins/variants/unit_variants.turbo_stream?target=${uniq_id}&establishment_id=${this.establishmentId}">
            <div class="autocomplete__search" data-forms--inputs--auto-complete-target="open" data-action="click->forms--inputs--auto-complete#open">
              <div class="flex gap-2" data-forms--inputs--auto-complete-target="search"></div>
              <span class="icon-Arrow-down pointer-events-none"></span>
            </div>
            <div class="autocomplete__content">
              <input type="search" class="autocomplete__input" placeholder="Rechercher" spellcheck="false" autocomplete="off" data-forms--inputs--auto-complete-target="input" data-action="keydown.enter->forms--inputs--auto-complete#preventEnter input->forms--inputs--auto-complete#search">
              <hr>
              <div id="options-${uniq_id}" class="autocomplete__options" data-forms--inputs--auto-complete-target="options"></div>
            </div>
            <input type="text" id="${uniq_id}" name="variant_id_unit_id" class="hidden" data-forms--inputs--auto-complete-target="value" data-action="change->transfer-form#getUnitVariantInformation">
          </div>
        </div></div>
      </td>
      <td id="zone-${uniq_id}"></td><td id="stock-${uniq_id}"></td><td id="input-${uniq_id}"></td>
      <td>
        <a class="btn btn-icon hover:bg-outline hover:bg-opacity-30 text-error" data-action="click->transfer-form#onRemoveLine"><span class="icon-Delete"></span></a>
      </td>
    </tr>
  `

  HtmlOnEstablishmentChange = (uniq_id) => `
    <thead><tr>
      <th>Produit</th>
      <th>Lieu de stockage</th>
      <th>Stock</th>
      <th>Quantité à transférer</th>
      <th></th>
    </tr></thead>
    <tbody>${this.htmlLine()}</tbody>
  `
}
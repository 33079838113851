import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["quantities"]
  static values = { quantity: Number }

  onChange(event){
    this.quantitiesTargets.forEach(element => {
      element.innerHTML = (event.target.value * element.dataset.value / this.quantityValue).toFixed(2)
    });
  }
}
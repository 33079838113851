// Entry point for the build script in your package.json
import '../assets/stylesheets/application.css';
import * as Turbo from '@hotwired/turbo'
import "@hotwired/turbo-rails"
import {Application} from "@hotwired/stimulus"
import {registerControllers} from 'stimulus-vite-helpers'

Turbo.session.drive = true
//Start stimulus application (Must be this scheme to enable hot module reload)
const application = Application.start()
window.Stimulus = application
application.debug = true
const addons = import.meta.glob('./addons/**/*_addon.{js,ts,tsx}', {eager: true})
registerControllers(application, addons)
const controllers = import.meta.glob('./controllers/**/*_controller.{js,ts,tsx}', {eager: true})
registerControllers(application, controllers)
const componentsControllers = import.meta.glob('./../components/**/*_controller.{js,ts,tsx}', {eager: true})
registerControllers(application, componentsControllers)